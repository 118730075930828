import { getGenericError } from './errors';

import {
  EARNINGS_SUMMARY_PATH,
  EARNINGS_TRANSACTIONS_PATH,
  EVENT_STREAM_PATH,
  EVENT_STREAM_EVENT_TYPES,
  EVENT_STREAM_APP_TYPES,
  SUSTAINABILITY_IMPACT_USER_PATH,
  OFFERS_PATH,
  PUBLIC_OFFERS_PATH,
  REWARDS_PATH,
  REWARD_ID_PARAM,
  REWARDS_CLAIM_PATH,
  REWARDS_USED_PATH,
  STORE_ID_PARAM,
  STORE_PATH,
  REWARDS_USER_PATH,
  REWARDS_OFFER_PATH,
  CONFIGURATION_PATH,
  REWARD_PATH,
  REWARDS_CODE_BY_UID_AND_BY_USER,
  REWARDS_STORE_PATH,
  CLOSET_CASH_DISCOUNT_CODES_PATH,
  SHOPIFY_STORE_PARAM,
  SHOPIFY_STORES,
  CLOSET_CASH_DISCOUNT_CODE_CLAIM_PATH,
  DISCOUNT_CODE_ID_PARAM,
  REGENERATION_SHIPPING_LABEL_PATH,
  REDEMPTION_CODE_PARAM,
  CATEGORIES_PATH,
  EARNINGS_CAROUSEL_PATH,
  USERS_ADDRESSES_PATH,
  USERS_STATUS_PATH,
  SEND_SIGN_IN_EMAIL_LINK_PATH,
  SHIPPING_PROVIDERS_PATH,
  SUBSCRIBE_PATH,
  REDEMPTIONS_PATH,
  GIFT_CARDS_BRANDS_PATH,
  GIFT_CARDS_CATEGORIES_PATH,
  GIFT_CARDS_BRAND_PATH,
  GIFT_CARDS_BRAND_SEARCH_PATH,
  BRAND_ID_PARAM,
  GIFT_CARDS_ISSUE_PATH,
  GIFT_CARD_ID_PARAM,
  GIFT_CARD_BY_UID_PATH,
  GIFT_CARDS_USER_PATH,
  GIFT_CARDS_USED_PATH,
  AUTH_USERS_PATH,
} from '../config/service';

import crud, { apiFetch } from '../../../api/crud';

const get = async ({ path, options, params }) => {
  const queryString = new URLSearchParams({
    ...(options ? { options: JSON.stringify(options) } : {}),
    ...params,
  }).toString();

  const pathString = queryString ? `${path}?${queryString}` : path;

  const data = await apiFetch({ path: pathString });

  return data;
};

const post = async ({ path, body }) => {
  const data = await apiFetch({ path, body: JSON.stringify(body), method: 'POST' });

  return data;
};

const put = async ({ path, body }) => {
  const data = await apiFetch({ path, body: JSON.stringify(body), method: 'PUT' });

  return data;
};

const patch = async ({ path, body }) => {
  const data = await apiFetch({ path, body: JSON.stringify(body), method: 'PATCH' });

  return data;
};

const checkResponseError = ({ status, error }) => {
  let errorMessage = { message: getGenericError() };

  if (status > 202) {
    if (error) {
      const { code, message } = error;

      errorMessage = { code, message: message || getGenericError() };
    }
  }

  return errorMessage;
};

const getUser = async (uid) => {
  let data = [];
  let error = '';

  try {
    data = await crud.get({
      path: '/users',
      options: {
        where: {
          uid,
        },
      },
    });
  } catch ({ message }) {
    error = message;
  }

  return { data: data[0] ?? null, error };
};

const getSummary = async () => {
  let data = {};
  let error = '';

  try {
    const response = await get({
      path: EARNINGS_SUMMARY_PATH,
    });

    data = response.data ?? {};
  } catch (err) {
    error = checkResponseError(err);
  }

  return { data, error };
};

const getTransactions = async () => {
  let data = [];
  let error = '';

  try {
    const response = await get({
      path: EARNINGS_TRANSACTIONS_PATH,
    });

    data = response.data ?? [];
  } catch (err) {
    error = checkResponseError(err);
  }

  return { data, error };
};

const sendEventStream = async ({
  event = EVENT_STREAM_EVENT_TYPES.USER_REGISTERED,
  application = EVENT_STREAM_APP_TYPES.TRASHIE,
  data = {},
}) => {
  let responseData = {};
  let error = '';

  try {
    const response = await post({
      path: EVENT_STREAM_PATH,
      body: {
        event,
        application,
        data,
      },
    });

    responseData = response.data ?? {};
  } catch (err) {
    error = checkResponseError(err);
  }

  return { data: responseData, error };
};

const syncUser = async (uid, email) => {
  let responseData = {};
  let error = '';

  try {
    const response = await post({
      path: '/users',
      body: {
        data: {
          email,
          uid,
          meta: {
            migrated: false,
            application: EVENT_STREAM_APP_TYPES.TRASHIE,
          },
        },
      },
    });

    responseData = response?.createData ?? {};
  } catch (err) {
    error = checkResponseError(err);
  }

  return { data: responseData, error };
};

const createOrFetchUser = async (email, method, shouldSubscribe = true) => {
  let responseData = {};
  let error = '';

  try {
    const response = await post({
      path: AUTH_USERS_PATH,
      body:
        {
          firstName: '',
          lastName: '',
          email,
          data: {
            shouldSubscribe,
            method,
          },

        },
    });

    responseData = response?.data ?? {};
  } catch (err) {
    error = checkResponseError(err);
  }
  return { data: responseData, error };
};

const updateUser = async (uid, { firstName, lastName }) => {
  let responseData = {};
  let error = '';

  try {
    const response = await put({
      path: '/users',
      body: {
        uid,
        data: {
          firstName,
          lastName,
        },
      },
    });

    responseData = response?.updateData ?? {};
  } catch (err) {
    error = checkResponseError(err);
  }

  return { data: responseData, error };
};

const getSustainabilityImpactUser = async () => {
  let responseData = [];
  let error = '';

  try {
    const response = await get({
      path: SUSTAINABILITY_IMPACT_USER_PATH,
    });

    responseData = response?.data ?? [];
  } catch (err) {
    error = checkResponseError(err);
  }

  return { data: responseData, error };
};

const getOffers = async () => {
  let responseData = [];
  let error = '';

  try {
    const response = await get({
      path: OFFERS_PATH,
    });

    responseData = response?.data ?? [];
  } catch (err) {
    error = checkResponseError(err);
  }

  return { data: responseData, error };
};

const getPublicOffers = async () => {
  let responseData = [];
  let error = '';

  try {
    const response = await get({
      path: PUBLIC_OFFERS_PATH,
    });

    responseData = response?.data ?? [];
  } catch (err) {
    error = checkResponseError(err);
  }

  return { data: responseData, error };
};

const getRewards = async ({ pageSize, page, ...rest }) => {
  let responseData = [];
  let error = '';

  try {
    const response = await get({
      path: REWARDS_PATH,
      params: {
        pageSize,
        page,
        ...rest,
      },
    });

    responseData = response?.data ?? [];
  } catch (err) {
    error = checkResponseError(err);
  }

  return {
    data: responseData,
    error,
  };
};

const getRewardsOffer = async ({ pageSize, page, ...rest }) => {
  let responseData = [];
  let error = '';

  try {
    const response = await get({
      path: REWARDS_OFFER_PATH,
      params: {
        pageSize,
        page,
        ...rest,
      },
    });

    responseData = response?.data ?? [];
  } catch (err) {
    error = checkResponseError(err);
  }

  return {
    data: responseData,
    error,
  };
};

const getReward = async (rewardId) => {
  let responseData = {};
  let error = '';

  try {
    const response = await get({
      path: REWARD_PATH.replace(REWARD_ID_PARAM, rewardId),
    });

    responseData = response?.data ?? {};
  } catch (err) {
    error = checkResponseError(err);
  }

  return {
    data: responseData,
    error,
  };
};

const patchRewardCodeIsUsed = async ({
  rewardId,
  isUsed,
}) => {
  let responseData = {};
  let error = '';

  try {
    const response = await patch({
      path: REWARDS_USED_PATH.replace(REWARD_ID_PARAM, rewardId),
      body: { isUsed },
    });

    responseData = response?.data ?? {};
  } catch (err) {
    error = checkResponseError(err);
  }

  return {
    data: responseData,
    error,
  };
};

const patchGiftCardsIsUsed = async ({
  giftCardsId,
  isUsed,
}) => {
  let responseData = {};
  let error = '';

  try {
    const response = await patch({
      path: GIFT_CARDS_USED_PATH.replace(GIFT_CARD_ID_PARAM, giftCardsId),
      body: { isUsed },
    });

    responseData = response?.data ?? {};
  } catch (err) {
    error = checkResponseError(err);
  }

  return {
    data: responseData,
    error,
  };
};

const getUserRewards = async ({
  pageSize,
  page,
  orderBy,
  orderType,
  type,
  status,
}) => {
  let responseData = {};
  let error = '';

  try {
    const response = await get({
      path: REWARDS_USER_PATH,
      params: {
        pageSize,
        page,
        orderBy,
        orderType,
        type,
        status,
      },
    });

    responseData = response?.data ?? {};
  } catch (err) {
    error = checkResponseError(err);
  }

  return {
    data: responseData,
    error,
  };
};

const getUserAddresses = async ({
  pageSize,
}) => {
  let responseData = {};
  let error = '';

  try {
    const response = await get({
      path: USERS_ADDRESSES_PATH,
      params: {
        pageSize,
      },
    });

    responseData = response?.data ?? {};
  } catch (err) {
    error = checkResponseError(err);
  }

  return {
    data: responseData,
    error,
  };
};

const getUserStatus = async (email) => {
  let responseData = {};
  let error = '';

  try {
    const response = await get({
      path: USERS_STATUS_PATH,
      params: {
        email,
      },
    });

    responseData = response?.data ?? {};
  } catch (err) {
    error = checkResponseError(err);
  }

  return {
    data: responseData,
    error,
  };
};

const getRewardCodeByUidAndUser = async (rewardCodeUid) => {
  let responseData = {};
  let error = '';

  try {
    const response = await get({
      path: REWARDS_CODE_BY_UID_AND_BY_USER.replace(REWARD_ID_PARAM, rewardCodeUid),
    });

    responseData = response?.data ?? {};
  } catch (err) {
    error = checkResponseError(err);
  }

  return {
    data: responseData,
    error,
  };
};

const getStoreRewards = async (storeId) => {
  let responseData = [];
  let error = '';

  try {
    const response = await get({
      path: REWARDS_STORE_PATH.replace(STORE_ID_PARAM, storeId),
    });

    responseData = response?.data ?? [];
  } catch (err) {
    error = checkResponseError(err);
  }

  return {
    data: responseData,
    error,
  };
};

const patchReward = async (rewardId, wfReturnUrl) => {
  let responseData = {};
  let error = null;

  try {
    const response = await patch({
      path: REWARDS_CLAIM_PATH.replace(REWARD_ID_PARAM, rewardId),
      ...(wfReturnUrl && { body: { wfReturnUrl } }),
    });

    responseData = response?.data ?? {};
  } catch (err) {
    error = checkResponseError(err);
  }

  return {
    data: responseData,
    error,
  };
};

const getStore = async (storeId) => {
  let data = [];
  let error = '';

  try {
    data = await crud.get({
      path: STORE_PATH.replace(STORE_ID_PARAM, storeId),
    });
  } catch ({ message }) {
    error = message;
  }

  return { data, error };
};

const getConfigurations = async (property) => {
  let error = '';
  let valueResponse;
  try {
    const { value } = await crud.get({ path: `${CONFIGURATION_PATH}/${property}` });
    valueResponse = value;
  } catch (err) {
    error = err;
  }

  return { value: valueResponse, error };
};

const getCCDiscountCodes = async (
  shop = SHOPIFY_STORES.CLOSET_CASH_STORE,
  maxAmount = 0,
) => {
  let responseData = [];
  let error = '';

  try {
    const response = await get({
      path: CLOSET_CASH_DISCOUNT_CODES_PATH.replace(SHOPIFY_STORE_PARAM, shop),
      params: { maxAmount },
    });

    responseData = response?.data ?? [];
  } catch ({ message }) {
    error = message;
  }

  return {
    data: responseData,
    error,
  };
};

const postCCDiscountCode = async (
  discountCodeId,
  shop = SHOPIFY_STORES.CLOSET_CASH_STORE,
) => {
  let responseData = {};
  let error = null;

  try {
    const response = await post({
      path: CLOSET_CASH_DISCOUNT_CODE_CLAIM_PATH
        .replace(SHOPIFY_STORE_PARAM, shop)
        .replace(DISCOUNT_CODE_ID_PARAM, discountCodeId),
    });

    responseData = response?.data ?? {};
  } catch (err) {
    error = checkResponseError(err);
  }

  return {
    data: responseData,
    error,
  };
};

const postShippingLabelRegenerate = async (redemptionCode) => {
  let responseData = {};
  let error = '';

  try {
    const response = await post({
      path: REGENERATION_SHIPPING_LABEL_PATH.replace(REDEMPTION_CODE_PARAM, redemptionCode),
    });
    responseData = response?.data ?? {};
  } catch (err) {
    error = checkResponseError(err);
  }

  return { data: responseData, error };
};

const getCategories = async ({ ...filters }) => {
  let responseData = {};
  let error = '';
  try {
    const response = await get({
      path: CATEGORIES_PATH,
      params: {
        ...filters,
      },
    });

    responseData = response?.data ?? {};
  } catch (err) {
    error = checkResponseError(err);
  }

  return {
    data: responseData,
    error,
  };
};

const getEarningsCarousel = async () => {
  let responseData = [];
  let error = '';

  try {
    const response = await get({
      path: EARNINGS_CAROUSEL_PATH,
    });

    responseData = response?.data ?? [];
  } catch (err) {
    error = checkResponseError(err);
  }

  return {
    data: responseData,
    error,
  };
};

const postSubscribeStatus = async (email, shouldSubscribe = false) => {
  let responseData = {};
  let error = '';

  try {
    const response = await post({
      path: SUBSCRIBE_PATH,
      body: {
        email,
        shouldSubscribe,
      },
    });

    responseData = response?.data ?? {};
  } catch (err) {
    error = checkResponseError(err);
  }

  return { data: responseData, error };
};

const fetchShippingProviders = async ({ storeUid, storeId, recyclingProductType }) => {
  let responseData = [];
  let error = '';

  try {
    const response = await post({
      path: SHIPPING_PROVIDERS_PATH,
      body: {
        storeUid,
        storeId,
        recyclingProductType,
      },
    });

    responseData = response?.data ?? [];
  } catch (err) {
    error = checkResponseError(err);
  }

  return {
    data: responseData,
    error,
  };
};

const sendSignInEmailLink = async ({
  email,
  returnUrl,
  data,
}) => {
  let responseData = {};
  let error = '';

  try {
    const response = await post({
      path: SEND_SIGN_IN_EMAIL_LINK_PATH,
      body: {
        email,
        returnUrl,
        data,
      },
    });

    responseData = response.data ?? {};
  } catch (err) {
    error = checkResponseError(err);
  }

  return { data: responseData, error };
};

const postRedemptionCode = async ({
  id, email, redemptionStatus, storeId, storeUid, fromAddress, shippingProviderId,
}) => {
  let responseData = {};
  let error = '';

  try {
    const response = await post({
      path: REDEMPTIONS_PATH,
      body: {
        data: {
          id,
          email,
          redemptionStatus,
          storeId,
          storeUid,
          fromAddress,
          shippingProviderId,
        },
      },
    });

    responseData = response?.data ?? {};
  } catch (err) {
    error = checkResponseError(err);
  }

  return { data: responseData, error };
};

const wait = (delay) => new Promise((resolve) => { setTimeout(resolve, delay); });

const fetchWithRetry = (url, tries = 3) => (
  fetch(url)
    .then(response => {
      if (response.ok) {
        return response.json();
      }

      return Promise.reject(response);
    })
    .catch(error => {
      if (tries < 1) {
        return { error };
      }

      return wait(500).then(() => fetchWithRetry(url, tries - 1));
    })
);

const fetchShippingProviderLocations = async ({
  URL,
  distance,
  zipcode,
  retailer,
  address,
  limit = 3,
}) => {
  let data = [];
  let error = '';

  if (!URL) {
    return {
      data,
      error,
    };
  }

  const searchParams = new URLSearchParams({
    distance,
    zipcode,
    retailer,
    address,
  });

  const result = await fetchWithRetry(`${URL}?${searchParams}`);

  if (result.error) {
    error = checkResponseError(result.error);
  } else {
    data = (result.locations ?? []).splice(0, limit);
  }

  return {
    data,
    error,
  };
};

const getTakeBackBagDetails = async (registerCode) => {
  let responseData = {};
  let error = '';

  try {
    const response = await get({
      path: `/redemptions/${registerCode}`,
    });
    if (response.error) {
      error = response.error;
    }
    responseData = response?.data ?? {};
  } catch (err) {
    error = checkResponseError(err);
  }
  return { data: responseData, error };
};

const getGiftCardsCategories = async ({ ...filters }) => {
  let responseData = {};
  let error = '';
  try {
    const response = await get({
      path: GIFT_CARDS_CATEGORIES_PATH,
      params: {
        ...filters,
      },
    });

    responseData = response?.data ?? {};
  } catch (err) {
    error = checkResponseError(err);
  }

  return {
    data: responseData,
    error,
  };
};

// GIFT_CARDS_BRAND_SEARCH_PATH
const getGiftCardsBrandsByName = async (name) => {
  let responseData = [];
  let error = '';

  try {
    const response = await get({
      path: GIFT_CARDS_BRAND_SEARCH_PATH,
      params: {
        name,
      },
    });

    responseData = response?.data?.brands ?? [];
  } catch (err) {
    error = checkResponseError(err);
  }
  return {
    data: responseData,
    error,
  };
};

const getGiftCardsBrands = async ({ pageSize, page, ...rest }) => {
  let responseData = [];
  let error = '';

  try {
    const response = await get({
      path: GIFT_CARDS_BRANDS_PATH,
      params: {
        pageSize,
        page,
        ...rest,
      },
    });

    responseData = response?.data ?? [];
  } catch (err) {
    error = checkResponseError(err);
  }

  return {
    data: responseData,
    error,
  };
};

const getGiftCardBrand = async (brandId) => {
  let responseData = {};
  let error = '';

  try {
    const response = await get({
      path: GIFT_CARDS_BRAND_PATH.replace(BRAND_ID_PARAM, brandId),
    });

    responseData = response?.data ?? {};
  } catch (err) {
    error = checkResponseError(err);
  }

  return {
    data: responseData,
    error,
  };
};

const issueGiftCard = async ({
  brand,
  amount,
}) => {
  let responseData = {};
  let error = null;

  try {
    const response = await post({
      path: GIFT_CARDS_ISSUE_PATH,
      body: { brand, amount },
    });

    responseData = response?.data ?? {};
  } catch (err) {
    error = checkResponseError(err);
  }

  return {
    data: responseData,
    error,
  };
};

const getGiftCardByUid = async (giftCardId) => {
  let responseData = {};
  let error = '';

  try {
    const response = await get({
      path: GIFT_CARD_BY_UID_PATH.replace(GIFT_CARD_ID_PARAM, giftCardId),
    });

    responseData = response?.data ?? {};
  } catch (err) {
    error = checkResponseError(err);
  }

  return {
    data: responseData,
    error,
  };
};

const getUserGiftCards = async ({
  pageSize,
  page,
  orderBy,
  orderType,
  status,
}) => {
  let responseData = {};
  let error = '';

  try {
    const response = await get({
      path: GIFT_CARDS_USER_PATH,
      params: {
        pageSize,
        page,
        orderBy,
        orderType,
        status,
      },
    });

    responseData = response?.data ?? {};
  } catch (err) {
    error = checkResponseError(err);
  }

  return {
    data: responseData,
    error,
  };
};

export {
  getUser,
  getSummary,
  getTransactions,
  sendEventStream,
  syncUser,
  createOrFetchUser,
  updateUser,
  getSustainabilityImpactUser,
  getOffers,
  getRewards,
  getUserRewards,
  getUserAddresses,
  getUserStatus,
  getReward,
  getStoreRewards,
  patchReward,
  patchRewardCodeIsUsed,
  getStore,
  getRewardsOffer,
  getPublicOffers,
  getConfigurations,
  getRewardCodeByUidAndUser,
  getCCDiscountCodes,
  postCCDiscountCode,
  postShippingLabelRegenerate,
  getCategories,
  getEarningsCarousel,
  sendSignInEmailLink,
  fetchShippingProviders,
  postSubscribeStatus,
  postRedemptionCode,
  fetchShippingProviderLocations,
  getTakeBackBagDetails,
  getGiftCardsCategories,
  getGiftCardsBrands,
  getGiftCardsBrandsByName,
  getGiftCardBrand,
  issueGiftCard,
  getGiftCardByUid,
  getUserGiftCards,
  patchGiftCardsIsUsed,
};
