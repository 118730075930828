import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import 'firebase/auth';
import firebase from 'firebase/app';
import moment from 'moment';
import { debounce } from 'lodash';
import classNames from 'classnames';

import { Button, BUTTON_SIZE, BUTTON_TYPE } from '../Button';
import DisclaimerPublic from '../DisclaimerPublic';
import DotsLoader from '../DotsLoader';
import GoogleButton from '../GoogleButton';
import Input from '../InputNew';

import { REGISTER_FROM_PARAM, REGISTER_OPTIN_PARAM, REGISTER_SIGNED_AT_PARAM, REGISTER_SIGNUP_PARAM, ROUTE_EMBED, REGISTER_SOURCE_PARAM, REGISTER_TOPIC_PARAM } from '../../config/routes';
import {
  PRIVACY_POLICY_URL,
  SUPPORT_URL,
  TERMS_OF_SERVICE_URL,
} from '../../config/externalURL';
import { getTrackerId, POSTHOG_CAPTURE_ATTRIBUTES, TRACKER_IDS } from '../../config/tracker';
import { sendSignInEmailLink, getUserStatus } from '../../utils/service';
import trashieLogo from '../../images/trashie-logo.webp';
import IconSend from '../../images/icon-send.svg';
import IconCloudy from '../../images/icon-cloudy.svg';
import IconEnvelope from '../../images/icon-envelope.svg';
import IconHourglass from '../../images/icon-hourglass.svg';
import {
  getGenericError,
  isAuthCredentialsError,
  isInvalidActionError,
  isTooManyRequestsError,
} from '../../utils/errors';
import { getNextButtonStyles, getOptionButtonStyles } from '../../utils/tbbRegistration';
import { SIGNUP_METHOD, SOURCE, TOPIC_SIGNUP } from '../../utils/login';
import { getSearchParam } from '../../utils/routes';
import { useAlert } from '../../../providers/AlertProvider';
import { signIn, signInWithGoogle } from '../../../../utils/auth';
import NavigationBar from '../NavigationBar/NavigationBar';
import { emailFormat } from '../../utils/emailFormatValidator';
import GeneralModal from '../GeneralModal';
import LoadingBar from '../LoadingBar';
import MessageContent from '../MessageContent';
import OptIn from '../OptIn';

import './GlobalLogin.scss';

export const viewType = {
  VIEW_EMAIL_FORM: 'emailForm',
  VIEW_EMAIL_PASSWORD_FORM: 'emailAndPassword',
  VIEW_LINK_SENT: 'linkSent',
  VIEW_LINK_CONFIRM: 'linkConfirm',
  VIEW_SENDING_LINK: 'sendingLink',
};

const LINK_ERROR_DEFAULT = {
  image: undefined,
  title: undefined,
  subtitle: undefined,
  cta: {
    text: undefined,
    onClick: undefined,
  },
};

const MODAL_CONTENT_DEFAULT = {
  image: undefined,
  title: undefined,
  subtitle: undefined,
  cta: {
    text: undefined,
    onClick: undefined,
  },
};

const LOCAL_STORAGE_EMAIL_KEY = 'emailForSignIn';

const {
  login: {
    emailLinkButton: trackerEmailLinkButton,
    passwordButton: trackerPasswordButton,
    googleButton: trackerGoogleButton,
  },
} = TRACKER_IDS;

const GlobalLogin = ({
  initialEmail = '',
  onViewChange,
  onEmailLinkSent,
  isParentLoading,
  storeConfig = {},
  emailLinkParams,
  setFinishProcessData,
  isEmbedded,
  isExternalEmbedded = false,
  view,
  setView,
}) => {
  const history = useHistory();
  const { search, pathname } = useLocation();
  const validRef = useRef(null);
  const setAlert = useAlert();

  const [email, setEmail] = useState(initialEmail);
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [modalContent, setModalContent] = useState(MODAL_CONTENT_DEFAULT);
  const [linkError, setLinkError] = useState(LINK_ERROR_DEFAULT);
  const [isUserEmail, setIsUserEmail] = useState(false);
  const [isVerifyingUserEmail, setIsVerifyingUserEmail] = useState(false);
  const [optIn, setOptIn] = useState(true);

  const loading = useMemo(() => (isParentLoading || isLoading), [isParentLoading, isLoading]);

  const handleFinish = useCallback((
    userRegistered,
    subscribeEmail,
    userRegisteredMethod,
  ) => {
    setFinishProcessData({
      userRegistered,
      userRegisteredMethod,
      subscribeEmail,
      optIn,
      search,
    });
  }, [optIn, search]);

  const handleRequestNewLink = useCallback(() => {
    setErrorMessage('');
    setLinkError(LINK_ERROR_DEFAULT);
    setView(viewType.VIEW_EMAIL_FORM);
  }, []);

  const handleUserLogin = useCallback(async () => {
    if (!email || !password) {
      return;
    }

    try {
      setErrorMessage('');
      await signIn(email, password);

      handleFinish(false, email);
    } catch (error) {
      if (isAuthCredentialsError(error)) {
        setErrorMessage('Invalid email or password. Please check your credentials and try again.');
        return;
      }

      if (isTooManyRequestsError(error)) {
        setErrorMessage('Access to this account has been temporarily disabled due to many failed login attempts. Please try again later.');
        return;
      }

      setErrorMessage(getGenericError());
    }
  }, [email, password]);

  const handleSignInWithGoogle = useCallback(async () => {
    try {
      window.localStorage.setItem('signUp_method', SIGNUP_METHOD.GOOGLE);
      window.localStorage.setItem('optIn', optIn);
      const user = await signInWithGoogle();

      handleFinish(
        user?.additionalUserInfo?.isNewUser,
        user?.additionalUserInfo?.profile?.email,
        SIGNUP_METHOD.GOOGLE,
      );
    } catch (error) {
      setAlert({
        type: 'notification', // TODO
        message: 'Unable to sign in with google',
        error,
      });
    }
  }, [handleFinish, optIn]);

  const signInWithEmailLink = useCallback(async (emailForSignIn) => {
    setIsLoading(true);

    firebase.auth().signInWithEmailLink(emailForSignIn, window.location.href)
      .then(async () => {
        // On signInWithEmailLink the parent component MUST handle specific logic in encoded data
        handleFinish();
      })
      .catch((error) => {
        const isInvalidLink = isInvalidActionError(error);

        setLinkError({
          image: IconCloudy,
          title: isInvalidLink ? 'THIS LOGIN LINK IS INVALID' : 'WE’VE ENCOUNTERED DIFFICULTIES WHILE VERIFYING THIS LINK',
          cta: {
            text: isInvalidLink ? 'SEND A NEW LINK' : 'TRY AGAIN',
            onClick: isInvalidLink ? handleRequestNewLink : () => window.location.reload(),
          },
        });
        setView(viewType.VIEW_LINK_SENT);
        setIsLoading(false);
      })
      .finally(() => {
        window.localStorage.removeItem(LOCAL_STORAGE_EMAIL_KEY);
      });
  }, [handleFinish, handleRequestNewLink]);

  const getUserEmailStatus = useCallback(userEmail => {
    setIsVerifyingUserEmail(true);

    const validateUserEmailStatus = async (validateUserEmail) => {
      setIsVerifyingUserEmail(true);

      if (validateUserEmail && emailFormat(validateUserEmail)) {
        const {
          data: userStatusData,
          error: userStatusError,
        } = await getUserStatus(validateUserEmail);

        setIsUserEmail(!userStatusError && userStatusData?.exists);
        setIsVerifyingUserEmail(false);
        return;
      }

      setIsUserEmail(false);
      setIsVerifyingUserEmail(false);
    };

    if (validRef.current && validRef.current.cancel) {
      validRef.current.cancel();
    }

    validRef.current = debounce(validateUserEmailStatus, 700);

    validRef.current(userEmail);
  }, []);

  const handleOnEmailChange = useCallback(async (newValue) => {
    setEmail(newValue);
    setErrorMessage(newValue && !emailFormat(newValue) ? 'Please enter a valid email to continue' : '');
    getUserEmailStatus(newValue);
  }, [getUserEmailStatus]);

  const handleOnPasswordChange = useCallback((newValue) => {
    setErrorMessage('');
    setPassword(newValue);
  }, []);

  const handleContinueWithPassword = useCallback(() => {
    setErrorMessage('');
    setView(viewType.VIEW_EMAIL_PASSWORD_FORM);
  }, []);

  const signUpTopic = useCallback(() => {
    if (isExternalEmbedded) {
      return TOPIC_SIGNUP.SIGN_UP_WEB_FLOW_LANDING_PAGE;
    }
    if (isEmbedded) {
      return TOPIC_SIGNUP.TBB_REGISTRATION_SIGNUP_FLOW;
    }
    return TOPIC_SIGNUP.SIGN_UP_WITH_LOGIN_LINK;
  }, [isExternalEmbedded, isEmbedded]);

  const handleSendLink = useCallback(async () => {
    if (!email) {
      setErrorMessage('Please enter your email');
      return;
    }

    if (isExternalEmbedded) {
      setView(viewType.VIEW_SENDING_LINK);
    } else {
      setModalContent({
        image: IconSend,
        title: 'SENDING LINK…',
        loader: true,
      });
    }

    let fromParam;
    const { state: locationState } = history.location;
    if (locationState?.from) {
      const {
        pathname: fromPathname,
        search: fromSearch,
        hash: fromHash,
      } = locationState.from;

      fromParam = `${fromPathname}${fromSearch && `?${fromSearch}`}${fromHash}`;
    }

    const { error } = await sendSignInEmailLink({
      email: email.toLowerCase(),
      returnUrl: `${pathname}${search}`.replace(ROUTE_EMBED, ''),
      data: {
        ...emailLinkParams,
        [REGISTER_OPTIN_PARAM]: !isUserEmail && optIn,
        [REGISTER_SIGNUP_PARAM]: !isUserEmail,
        [REGISTER_FROM_PARAM]: fromParam,
        [REGISTER_SOURCE_PARAM]: isExternalEmbedded ? SOURCE.EMBED : SOURCE.APP,
        [REGISTER_TOPIC_PARAM]: signUpTopic(),
      },
    });

    if (error) {
      setLinkError({
        image: IconCloudy,
        title: 'WE’VE ENCOUNTERED DIFFICULTIES WHILE SENDING THE LINK',
        cta: {
          text: 'TRY AGAIN',
          onClick: handleRequestNewLink,
        },
      });

      setModalContent(MODAL_CONTENT_DEFAULT);
      return;
    }

    onEmailLinkSent?.();
    window.localStorage.setItem(LOCAL_STORAGE_EMAIL_KEY, email.toLowerCase());
    setView(viewType.VIEW_LINK_SENT);
    setModalContent(MODAL_CONTENT_DEFAULT);
  }, [email, search, optIn, isUserEmail, handleRequestNewLink]);

  const handleConfirmEmail = useCallback(() => {
    if (!email) {
      setErrorMessage('Please enter your email');
      return;
    }

    signInWithEmailLink(email);
  }, [email, signInWithEmailLink]);

  const handleForgotPassword = useCallback(() => {
    setModalContent({
      image: IconEnvelope,
      title: 'ACCESS VIA LOGIN LINK',
      subtitle: `We will send you an email with a temporary login link to ${email}`,
      cta: {
        text: 'SEND ME A LOGIN LINK',
        onClick: handleSendLink,
      },
    });
  }, [email, handleSendLink]);

  const handleContactSupport = useCallback(() => {
    window.location.href = SUPPORT_URL;
  }, []);

  const isActiveView = useCallback((viewId) => (
    viewId === view
  ), [view]);

  useEffect(() => {
    if (onViewChange) {
      onViewChange(view);
    }
  }, [onViewChange, view]);

  useEffect(() => {
    // Email link handling
    if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
      setIsLoading(true);

      const emailForSignIn = window.localStorage.getItem(LOCAL_STORAGE_EMAIL_KEY);

      if (!emailForSignIn) {
        setView(viewType.VIEW_LINK_CONFIRM);
        setIsLoading(false);
      } else {
        setView(viewType.VIEW_LINK_SENT);
        setEmail(emailForSignIn);

        // Params handling
        const query = new URLSearchParams(search);
        const signedAt = getSearchParam(query, REGISTER_SIGNED_AT_PARAM);

        if (signedAt && moment(signedAt).add(10, 'minutes').isBefore(moment())) {
          setLinkError({
            image: IconHourglass,
            title: 'THIS LOGIN LINK HAS EXPIRED',
            cta: {
              text: 'SEND A NEW LINK',
              onClick: handleRequestNewLink,
            },
          });
          setIsLoading(false);
        } else {
          signInWithEmailLink(emailForSignIn);
        }
        // End of params handling
      }
    }
    // End of email link handling

    return () => {
      validRef.current = null;
    };
  }, []);

  if (isActiveView(viewType.VIEW_LINK_SENT) && loading) {
    return (
      <LoadingBar className="GlobalLogin__loader" />
    );
  }

  return (
    <div className={classNames('GlobalLogin', { isEmbedded, isExternalEmbedded })}>
      <div className="GlobalLogin__content">
        {!isActiveView(viewType.VIEW_LINK_SENT) && (
          <img
            className={classNames('GlobalLogin__logo', { mainLogin: !isEmbedded, isExternalEmbedded })}
            src={trashieLogo}
            alt="logo"
          />
        )}
        {isActiveView(viewType.VIEW_EMAIL_FORM) && (
          <>
            <GoogleButton
              onClick={() => handleSignInWithGoogle()}
              trackerProps={{
                [POSTHOG_CAPTURE_ATTRIBUTES.TRACKER_ID]: getTrackerId(trackerGoogleButton),
              }}
            />
            <p className="GlobalLogin__content--label">
              OR
            </p>
            <div className="GlobalLogin__content--form">
              <Input
                type="text"
                id="email"
                placeholder="Enter your email…"
                value={email}
                onChange={handleOnEmailChange}
                errorMessage={errorMessage}
                disabled={loading}
              />
              {emailFormat(email) && (
                isVerifyingUserEmail ? (
                  <DotsLoader className="GlobalLogin__content--dotsLoader" />
                ) : (
                  <>
                    <Button
                      type={isExternalEmbedded ? BUTTON_TYPE.PRIMARY : BUTTON_TYPE.QUATERNARY}
                      size={BUTTON_SIZE.LARGE}
                      onClick={handleSendLink}
                      style={getNextButtonStyles(storeConfig)}
                      disabled={loading}
                      trackerProps={{
                        [POSTHOG_CAPTURE_ATTRIBUTES.TRACKER_ID]:
                          getTrackerId(trackerEmailLinkButton),
                      }}
                    >
                      {loading ? (
                        <DotsLoader />
                      ) : (
                        (isUserEmail && 'SEND ME A LOGIN LINK') || 'CREATE ACCOUNT'
                      )}
                    </Button>
                    {isUserEmail && (
                      <Button
                        type={isExternalEmbedded ? BUTTON_TYPE.SECONDARY : BUTTON_TYPE.QUINARY}
                        size={BUTTON_SIZE.LARGE}
                        onClick={handleContinueWithPassword}
                        style={getNextButtonStyles(storeConfig)}
                        disabled={loading}
                        trackerProps={{
                          [POSTHOG_CAPTURE_ATTRIBUTES.TRACKER_ID]:
                            getTrackerId(trackerPasswordButton),
                        }}
                      >
                        CONTINUE WITH PASSWORD
                      </Button>
                    )}
                  </>
                )
              )}
              <OptIn
                className="GlobalLogin__content--form-optIn"
                checked={optIn}
                onChange={() => setOptIn(!optIn)}
                disabled={loading || isVerifyingUserEmail}
              />
            </div>
          </>
        )}
        {isActiveView(viewType.VIEW_LINK_SENT) && (
          <>
            {!linkError.title && (
              <NavigationBar
                onGoBack={handleRequestNewLink}
                className={classNames('GlobalLogin__navigation', { isExternalEmbedded })}
              />
            )}
            <div className="GlobalLogin__content--form">
              {linkError.title ? (
                <MessageContent {...linkError} />
              ) : (
                <MessageContent
                  image={IconSend}
                  title="LINK SENT TO:"
                  subtitle={email}
                  message={`Click on the link in your email to complete ${isUserEmail ? 'log in' : 'sign up'}.`}
                  embedContent={isExternalEmbedded}
                />
              )}
            </div>
            <div className="GlobalLogin__content--option">
              <p className="GlobalLogin__content--option-text">
                {linkError.title ? 'Still not working?' : 'Didn’t get the link?'}
              </p>
              <Button
                className="GlobalLogin__content--option-link"
                type={BUTTON_TYPE.LINK_QUINARY}
                style={getOptionButtonStyles(storeConfig)}
                onClick={linkError.title ? handleContactSupport : handleRequestNewLink}
              >
                {linkError.title ? 'CONTACT SUPPORT' : 'REQUEST A NEW LINK'}
              </Button>
            </div>
          </>
        )}
        {isActiveView(viewType.VIEW_SENDING_LINK) && (
          <div className="GlobalLogin__content--form">
            <MessageContent
              image={IconSend}
              title="SENDING LINK..."
              loader
              embedContent={isExternalEmbedded}
            />
          </div>
        )}
        {isActiveView(viewType.VIEW_LINK_CONFIRM) && (
          <div className="GlobalLogin__content--form">
            <div className="GlobalLogin__content--header">
              <p className="subtitle">ENTER YOUR EMAIL TO CONTINUE:</p>
            </div>
            <div className="GlobalLogin__content--form">
              <Input
                type="text"
                id="email"
                placeholder="Enter your email…"
                value={email}
                onChange={handleOnEmailChange}
                errorMessage={errorMessage}
                disabled={loading}
              />
              {emailFormat(email) && (
                <Button
                  type={BUTTON_TYPE.QUATERNARY}
                  size={BUTTON_SIZE.LARGE}
                  onClick={handleConfirmEmail}
                  style={getNextButtonStyles(storeConfig)}
                  disabled={loading}
                >
                  {loading ? <DotsLoader /> : 'CONTINUE'}
                </Button>
              )}
            </div>
          </div>
        )}
        {isActiveView(viewType.VIEW_EMAIL_PASSWORD_FORM) && (
          <div className="GlobalLogin__content--form">
            <Input
              type="text"
              id="userEmail"
              placeholder="Email"
              value={email}
              onChange={handleOnEmailChange}
              errorMessage={Boolean(errorMessage)}
              disabled
            />
            <Input
              type="password"
              id="password"
              placeholder="Password"
              value={password}
              onChange={handleOnPasswordChange}
              errorMessage={errorMessage}
              disabled={loading}
            />
            {emailFormat(email) && (
              <>
                <Button
                  type={isExternalEmbedded ? BUTTON_TYPE.PRIMARY : BUTTON_TYPE.QUATERNARY}
                  size={BUTTON_SIZE.LARGE}
                  onClick={handleUserLogin}
                  style={getNextButtonStyles(storeConfig)}
                  disabled={loading || !password}
                >
                  {loading ? <DotsLoader /> : 'Log in'}
                </Button>
                <div className="GlobalLogin__content--option">
                  <p className="GlobalLogin__content--option-text">
                    Password missing?
                  </p>
                  <Button
                    className="GlobalLogin__content--option-link"
                    type={BUTTON_TYPE.LINK_QUINARY}
                    onClick={handleForgotPassword}
                    style={getOptionButtonStyles(storeConfig)}
                    disabled={loading}
                  >
                    USE A LOGIN LINK INSTEAD
                  </Button>
                </div>
              </>
            )}
          </div>
        )}
      </div>
      {!isActiveView(viewType.VIEW_SENDING_LINK) &&
        !isActiveView(viewType.VIEW_LINK_SENT) &&
        !isActiveView(viewType.VIEW_LINK_CONFIRM) &&
        (
          <DisclaimerPublic
            termsUrl={TERMS_OF_SERVICE_URL}
            policyUrl={PRIVACY_POLICY_URL}
          />
        )}
      <GeneralModal
        showModal={modalContent.title}
        showModalHeader={false}
        showModalFooter={false}
        canClose={false}
      >
        <MessageContent {...modalContent} />
      </GeneralModal>
    </div>
  );
};

export default GlobalLogin;
