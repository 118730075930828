import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import DashboardSection from '../../../global/DashboardSection';
import { getGenericError } from '../../../utils/errors';
import { getRewards, getConfigurations } from '../../../utils/service';
import {
  ANCHOR_REWARDS,
  REWARD_ID_ROUTE_PARAM,
  ROUTE_REWARDS,
  ROUTE_REWARDS_STORE_REWARD_ID,
  STORE_ID_ROUTE_PARAM,
} from '../../../config/routes';
import { getTrackerId, POSTHOG_CAPTURE_ATTRIBUTES, TRACKER_IDS } from '../../../config/tracker';
import RewardItem from '../RewardItem';
import Slider, { ARROWS_VERTICAL_POSITION, SLIDER_TYPES } from '../../../global/Slider';

import './RewardsPreview.scss';

const PREVIEW_PAGE_SIZE = 10;

const {
  dashboard: {
    trashiecashRewards: {
      viewAllButton: trackerViewAllButton,
    },
  },
} = TRACKER_IDS;

const RewardsPreview = () => {
  const history = useHistory();

  const [rewards, setRewards] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [rewardProperties, setRewardProperties] = useState({});
  const [isLoadingConfig, setIsLoadingConfig] = useState(true);

  const loadRewards = useCallback(async () => {
    setIsLoading(true);

    const {
      data,
      error: rewardsError,
    } = await getRewards({
      pageSize: PREVIEW_PAGE_SIZE,
      page: 1,
      ...{ exchangeValueMin: '1' },
    });

    if (rewardsError) {
      setRewards([]);
      setError(rewardsError.message || getGenericError());
      setIsLoading(false);
      return;
    }

    setRewards(data.rewards);
    setError('');
    setIsLoading(false);
  }, []);

  const rewardsConfig = useCallback(async () => {
    setIsLoadingConfig(true);
    const { value, error: configError } = await getConfigurations('rewardProperties');
    if (!configError) {
      setRewardProperties(value);
    }
    setIsLoadingConfig(false);
  }, []);

  const handleOnClick = useCallback((rewardItem) => {
    const relativePath = ROUTE_REWARDS_STORE_REWARD_ID
      .replace(STORE_ID_ROUTE_PARAM, rewardItem.store?.id)
      .replace(REWARD_ID_ROUTE_PARAM, rewardItem.uid);

    history.push(relativePath);
  }, [history]);

  useEffect(() => {
    loadRewards();
    rewardsConfig();
  }, []);

  return (
    <DashboardSection
      anchor={ANCHOR_REWARDS}
      title="Premium Rewards"
      subTitle="You’ve earned it — redeem TrashieCash™ for something awesome."
      onClick={() => history.push(ROUTE_REWARDS)}
      isLoading={isLoading || isLoadingConfig}
      error={error}
      noData={rewards.length ? undefined : 'No Rewards available at the moment'}
      ctaTrackerProps={{
        [POSTHOG_CAPTURE_ATTRIBUTES.TRACKER_ID]: getTrackerId(trackerViewAllButton),
      }}
    >
      <div className="RewardsPreview">
        <Slider
          type={SLIDER_TYPES.OVERFLOW}
          autoplay={false}
          rewind={false}
          paginationDots={false}
          arrowsVerticalPosition={ARROWS_VERTICAL_POSITION.TOP}
          gap={32}
          slidesPerPage={4}
          slides={rewards.map(rewardItem => ({
            key: `reward-preview-${rewardItem.uid}`,
            slide: (
              <RewardItem
                key={`slide-reward-${rewardItem.uid}`}
                rewardItem={rewardItem}
                onClick={handleOnClick}
                rewardProperties={rewardProperties}
              />
            ),
          }))}
        />
      </div>
    </DashboardSection>

  );
};

export default RewardsPreview;
